import React, { Component } from 'react';
import logo from '../assets/images/King-Logo-Horizontal-4.png';
import './NavMenu.css';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
    const imgStyle = { height: '75px', clear: 'right' };

    return (
      <div className="navbar navbar-default navbar-fixed-top">
        <div className="container">
          <div className="navbar-header">
            <a className="navbar-brand" href="https://kinginstrumentco.com" title="King Instrument Company">
              <img src={logo} alt="king Instrument Company logo" style={imgStyle} />
            </a>
          </div>
        </div>
      </div>
    );
  }
}
